import React from "react";
import logo from '../images/top-logo.png';
import footermail from '../images/footer-mail-icon.png';
import footerfacebook from '../images/footer-facebook-icon.png';
import footertwitter from '../images/footer-twitter-icon.png';
import footerskype from '../images/footer-skype-icon.png';

function Footer() {
  return (
    <>
    <section className="getknowSec">
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <h2>Get to know us even better,<br /> what we do and what makes us classy!</h2>
          </div>
          <div className="col-lg-4 text-center mt-5">
            <a href="#" className="primaryBtn">Talk to our Experts</a>
          </div>
        </div>
      </div>
    </section>
    <section className="footerForm" id="formsection">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <h6>Get Started</h6>
            <h2>Our <span>Eclipton</span></h2>
            <p>Welcome to Eclipton Technologies, a renowned leader in technology solutions across four continents. With a robust track record of successfully delivering over 500 projects, we are proud to be at the forefront of innovation in the global market. Our journey began with a vision to redefine technology solutions, and today, we continue to expand our reach globally, providing cutting-edge services to clients worldwide.</p>
            <ul>
              <li>Our team of experts is committed to delivering tailored solutions that drive success and propel businesses forward in the ever-evolving digital landscape.</li>
              <li> Join us on our journey as we shape the future of technology together.</li>
            </ul>
          </div>
          <div className="col-lg-6">
            <form className="pt-4">
            <div class="row">
              <div class="col-12">
                <input type="text" class="form-control" placeholder="Full Name" aria-label="Full Name" />
              </div>
              <div class="col-12">
                <input type="email" class="form-control" placeholder="Email" aria-label="Email" />
              </div>
              <div class="col-4">
                <input type="text" class="form-control" placeholder="Country Code" aria-label="Country Code" />
              </div>
              <div class="col-8">
                <input type="text" class="form-control" placeholder="Phone No" aria-label="Phone No" />
              </div>
              <div class="col-12">
                <textarea class="form-control" id="exampleFormControlTextarea1" placeholder="Message..." rows="3"></textarea>
              </div>
              <div class="text-end">
                <button type="submit" class="primaryBtn">Submit</button>
              </div>
            </div>
            </form>
          </div>
        </div>
      </div>
    </section>
    <footer id="contactus">
      <div class="container">
          <div className="row">
            <div className="col-md-12">
              <img src={logo} className="pb-4" alt="Logo" style={{ width: '130px' }} />
            </div>
          </div>
          <div class="row">
            <div className="col-lg-5 col-md-6">
              <div className="row">
                <div className="col-md-12">
                  <h4>Eclipton Technologies Private Limited</h4>
                </div>
              </div>
              <div className="row">
                {/* <div className="col-md-6">
                  <div>
                    <h5>Salem</h5>
                    <p>533, 535, II Floor, Trichy Main Road, </p>
                    <p>Gugai, Near Gandhi statue,</p>
                    <p>Salem, Tamil Nadu, India 636 006</p>
                  </div>
                </div> */}
                <div className="col-md-6">
                  <div>
                    <h5>Bangalore</h5>
                    <p>A1901, A Block, 19th Floor,</p>
                    <p>Miraya Rose, HAL Old Airport Rd,</p>
                    <p>Whitefield, Bangalore,</p>
                    <p>Karnataka - 560 066</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div>
                    <h5>Delhi</h5>
                    <p>140C Hauz Rani,</p>
                    <p>Malviya Nagar,</p>
                    <p>New Delhi - 110 017</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <h4>Our Products and Services</h4>
              <a href="#">Marketplace Development</a>
              <a href="#">Blockchain Software Development</a>
              <a href="#">Payment Gateway Development</a>
              <a href="#">Consultation</a>
            </div>
            <div className="col-lg-3 col-md-6">
              <h4>Get Our Newsletter</h4>
              <div className="content">
                <div className="input-group">
                  <input type="email" className="form-control" placeholder="Enter your Email here..." />
                  <span className="input-group-btn">
                  <button className="primaryBtn" type="submit">Send</button>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="copyRight">   
            <div className="row">    
              <div className="col-md-6">
                <div className="copyRight-text"><a href="#">Terms & Conditions</a> | <a href="#">Privacy Policy</a></div>
              </div>
              <div className="col-md-6 text-end">
                <p class="text-white">&copy; Eclipton 2024</p>
              </div>
            </div>     
          </div>
        </div>
    </footer>
    </>
  );
}

export default Footer;
